<template>
  <div
    class="allBlogs"
    v-loading="isLoading"
    v-infinite-scroll="loadMoreData"
    infinite-scroll-immediate="false"
    infinite-scroll-distance="0"
  >

    <template v-if="topicId !== '0' && topic.creator">
      <div class="topic-card">
          <div class="topic-head">
            <h3 class="title">#{{topic.name}}#</h3>
            <p class="goto" @click="goto">&lt;返回</p>
          </div>
          <div class="topic-userinfo">
            <UserAvatar
            :user="topic.creator"
            size="24"
            fontSize="12"
            medalSize="38"
          >
            <template #slot>
              <p>{{topic.creator.name}}<span>发起</span>
                <span class="split"></span>
                <span class="number">{{topic.feeds_count}}动态</span>
                <span class="number">{{topic.like_count}}动态点赞数</span>
              </p>
            </template>
          </UserAvatar>
        </div>
      </div>
    </template>
    <template v-if="voteId !== '0'" >
      <div class="topic-card vote-card">
          <div class="topic-head">
            <p class="goto" @click="goto">&lt;返回</p>
          </div>
      </div>
    </template>
    <BlogsList
      :feedList="feedList"
      @deleteFeed="deleteBlog"
      @likeFeed="updateFeedInfo($event, 1, feedList)"
      @collectFeed="updateFeedInfo ($event, 2, feedList)"
      @refresh="getList"
    ></BlogsList>
    <empty-data v-if="!isLoading && feedList.length == 0"
      :image="type === 2 ? 'no-news' : 'no-content'"
      :description="type === 0 ? '暂无内容' : type === 1 ? '暂无动态' : '暂无资讯'"
      ></empty-data>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { getFeedsList, getFeedsFeedInfo } from '@/apis/blogs.js'
import { feedsTopicFeed } from '@/apis/hotTopic'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import eventBus from '@/utils/eventBus.js'
import BlogsList from '@/components/blog/BlogsList.vue'
import { updateFeedInfo } from '@/utils/userCenter.js'

export default {
  components: { BlogsList },
  setup () {
    const state = reactive({
      feedList: [],
      page: 1,
      limit: 10,
      type: 0,
      topicId: '0', // 话题ID
      isLoading: true,
      topic: {},
      voteId: '0',
      voteDesc: {}
    })
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    onMounted(() => {
      state.type = route.name === 'allBlogs' ? 0 : route.name === 'dynamics' ? 1 : 2
      state.topicId = route.query.topicId || '0'
      state.voteId = route.query.voteId || '0'
      init()
    })

    function getList () {
      state.isLoading = true
      getFeedsList({
        type: state.type,
        limit: state.limit,
        page: state.page
      })
        .then((res) => {
          dealResponse(res)
          // 清除微博小红点
          if (state.type === 0 || state.type === 1) {
            store.commit('editwebSocketType', 0)
          }
          state.isLoading = false
        })
        .catch((err) => {
          store.commit('setLoadMore', false)
          console.log(err)
          state.isLoading = false
        })
    }
    eventBus.$on('uploadBlogsData', init)
    function getTopicList () {
      feedsTopicFeed({
        id: state.topicId,
        page: state.page
      })
        .then((res) => {
          dealResponse(res)
        })
        .catch((err) => {
          store.commit('setLoadMore', false)
          console.log(err)
        })
    }
    function getVoteList () {
      getFeedsFeedInfo({
        id: state.voteId
      })
        .then((res) => {
          store.commit('setLoadMore', false)
          if (res.code === 0) {
            if (!res.data) return store.commit('setNoMore', true)
            state.voteDesc = res.data.vote
            state.feedList.push(res.data)
            state.isLoading = false
          }
        })
        .catch((err) => {
          store.commit('setLoadMore', false)
          console.log(err)
        })
    }
    function dealResponse (res) {
      store.commit('setLoadMore', false)
      if (res.code === 0) {
        if (!res.data.data.length) return store.commit('setNoMore', true)
        state.topic = res.data.topic
        res.data.data.forEach(i => {
          if (!state.feedList.find(item => i.id === item.id)) {
            state.feedList.push(i)
          }
        })

        if (res.data.data.length < state.limit && state.page !== 1) {
          if (
            (state.page !== 1 && res.data.data.length === 0) ||
            (state.page === 1 && res.data.data.length !== 0)
          ) {
            store.commit('setNoMore', true)
          }
        }
      }
      state.isLoading = false
      store.dispatch('hotTopic/getHotTopic')
    }
    function loadMoreData () {
      if (state.feedList.length !== 0 && !store.state.noMore && !store.state.loadMore) {
        store.commit('setLoadMore', true)
        state.page += 1
        if (state.topicId === '0' && state.voteId === '0') {
          getList()
        } else if (state.topicId !== '0') {
          getTopicList()
        } else {
          store.commit('setLoadMore', false)
        }
      }
    }

    function init () {
      state.feedList = []
      state.page = 1
      store.commit('setNoMore', false)
      if (state.topicId === '0' && state.voteId === '0') {
        getList()
      } else if (state.topicId !== '0') {
        getTopicList()
      } else {
        getVoteList()
      }
    }

    // 删除 更新话题 和个人中心
    function deleteBlog (id) {
      const index = state.feedList.findIndex((item) => item.id === id)
      const delfeedItem = state.feedList.splice(index, 1)
      store.dispatch('hotTopic/getHotTopic')
      if (delfeedItem[0].vote_id) { // 判断删除的是投票动态才进行更新
        store.dispatch('vote/getProgressVote')
      }
    }

    const goto = () => {
      router.push({ path: '/blogs/dynamics' })
    }

    return {
      ...toRefs(state),
      loadMoreData,
      updateFeedInfo,
      getList,
      deleteBlog,
      goto
    }
  }
}
</script>

<style lang="less" scoped>
  .allBlogs{
    min-height: 404px;
    .el-empty{
      background-color: @colorWhite;
      border: 1px solid #F1F1F1;
      border-top: none;
    }

    .topic-card {
      margin-top: 8px;
      padding: 16px 24px;
      border: 1px solid #F1F1F1;
      border-radius: 6px;
      background: #fff;
      .topic-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: @active-text-color;
        .title {
          font-size: 16px;
        }
        .goto {
          font-size: 14px;
          cursor: pointer;
        }
      }
      &.vote-card{
        .topic-head{
          justify-content: flex-end;
        }
      }

      .topic-userinfo {
        margin-top: 12px;
        :deep(.user-avatar) {
          justify-content: flex-start;
          align-items: center;
          .el-image {
            margin: 0 !important;
          }
          p:first-child {
            margin: 0 !important;
          }
          p:last-child {
            display: flex;
            margin-left: 8px;
            align-items: center;
            color: #333;
            font-size: 14px;

            span {
              color: #999;
              &:first-child {
                margin-left: 5px;
              }
              &.split,
              &.number {
                font-size: 12px;
                margin-right: 10px;
              }
              &.split {
                margin: 0 15.5px;
                height: 12px;
                border-left: 1px solid #E0E0E0;
              }
            }
          }
        }
      }

    }
  }
</style>
